import * as React from 'react'
import styled from 'styled-components'
import Header from './Header';

const FullLayout = ({children}) => {
    
    const Full = styled.div`
        width: 100%; 
        margin: 0 auto;
            @media only screen
            and (min-device-width: 375px)
            and (max-device-width: 667px)
            and (-webkit-min-device-pixel-ratio: 2)
            and (orentation: portrait) {
                width: 100%;
            }
    `;
    
    return(
        <div>
        <Header/>
        <Full>
            {children}
        </Full>
        </div>
    )
}

export default FullLayout