import * as React from 'react'
import {useState} from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { motion } from 'framer-motion'
// import { Video } from 'gatsby-video'
import { MenuToggle } from './MenuToggle'

const NavContainer = ({props}) => {
    
    const NavigationalContainer = styled.ul`
        width: auto;
        height: auto;
        list-style-type: none;
        padding: 0px;
        margin: 0px;
    `;

    const NavigationalItem = styled.li`
        width: 100%;
        height: 100%;
        text-align: center;
        padding-bottom: 44px;

       & .link {
           color: #FDF9F0;
           font-size: 54px;
           text-decoration: none;
       }

       @media only screen
        and (min-width: 320px)
        and (max-width: 720px) {
            & .link {
                font-size: 24px;
            }
        }
    `;

    return(
        <NavigationalContainer>
            <NavigationalItem><Link className="link" to="/appendix/">Appendix</Link></NavigationalItem>
            <NavigationalItem><Link className="link" to="/minnen-kusinen/">Minnen - kusinen</Link></NavigationalItem>
            <NavigationalItem><Link className="link" to="/minnen-den-osynlige/">Minnen - den osynlige</Link></NavigationalItem>
            <NavigationalItem><Link className="link" to="/minnen-en-vacker-yngling/">Minnen - en vacker yngling</Link></NavigationalItem>
            <NavigationalItem><Link className="link" to="https://biografi.rolfborjlind.com/">Biografin 📕</Link></NavigationalItem>
        </NavigationalContainer>
    )
}



const Header = () => {

    const Logotype = styled.h1`
        font-size: 24px;
        color: #FDF9F0;
        & .logo {
            color: #FDF9F0;
            text-decoration: none;
        }
        @media only screen
        and (max-width: 320px) {
            font-size: 24px;
        }
    `;

    const Container = styled.div`
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap; 
        justify-content: space-between;
        align-items: center;
        align-content: center;
    `;

    const MastHeader = styled.header`
        width: 100%; 
        height: 100px;
        position: fixed; 
        top: 0px; 
        left: 0px;
        z-index: 9999999;
        padding: 0px 20px;  
    `;

    const SideMenu = styled.div`
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0px;
        left: -120%;
        transition: all ease-in-out 250ms;
        background: rgba(0,0,0,1);
        z-index: 99999; 
        padding: 0px; 
        margin: 0px; 
        display: flex; 
        flex-direction: row;
        flex-wrap: nowrap; 
        justify-content: center;
        align-items: center;
        align-content: center;
    `;

    // const Loading = styled.div`
    //     width: 100vw;
    //     height: 100vh;
    //     position: absolute;
    //     top: 0px;
    //     left: 0px;
    //     background: red;
    //     z-index: 99999; 
    //     padding: 0px; 
    //     margin: 0px; 
    //     display: flex; 
    //     flex-direction: row;
    //     flex-wrap: nowrap; 
    //     justify-content: center;
    //     align-items: center;
    //     align-content: center;
    // `;


    //   const [isOpen, setIsOpen] = useState(false)

      const activateBurger = () => {
        document.getElementById("menu").classList.toggle("is-active")
        document.getElementById("sideMenu").classList.toggle("show")
      }


    return(
        <div>
        <MastHeader>
            <Container>
                <Logotype><Link className="logo" to="/">Rolf Börjlind</Link></Logotype>
                    <button id="menu" className="hamburger--spin hamburger" type="button" onClick={activateBurger}>
                        <span className="hamburger-box">
                        <span className="hamburger-inner"></span>
                        </span>
                    </button> 
            </Container>
        </MastHeader>

        <SideMenu id="sideMenu">
            <NavContainer />
        </SideMenu>
        </div>
    )
}

export default Header